<template>
  <!-- 编辑面经 -->
  <div id="app" style="background: #f4f4f4">
    <div class="cson-container">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/interviewExperience' }"
            >面经</el-breadcrumb-item
          >
          <el-breadcrumb-item>撰写面经</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="company-warp">
        <div class="clearfix">
          <div class="ms-content">
            <div class="my-card">
              <div class="card-head">
                <div class="card-tit" style="font-size: 20px">我的面经</div>
              </div>
              <div :class="['card-boder', covered ? 'hidden' : '']">
                <div class="cover" v-if="covered" @click="covered = false">
                  <i class="el-icon-d-arrow-left"></i>展开基础信息
                </div>
                <el-form
                  ref="form"
                  :model="form"
                  label-width="85px"
                  class="ms-form"
                  :rules="myDetailRules"
                >
                  <el-form-item label="面试岗位" prop="post">
                    <el-select
                      v-model="form.post"
                      placeholder="请选择岗位"
                      style="margin-bottom: 0"
                    >
                      <el-option label="码农SDE" :value="0"></el-option>
                      <el-option label="前端FrontEndEng" :value="1"></el-option>
                      <el-option label="移动端MobileEng" :value="2"></el-option>
                      <el-option
                        label="机器学习MachineLearningEng"
                        :value="3"
                      ></el-option>
                      <el-option
                        label="数据科学DataScience"
                        :value="4"
                      ></el-option>
                      <el-option label="产品PM" :value="5"></el-option>
                      <el-option label="管理Manager" :value="6"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="求职目标" prop="target">
                    <el-radio-group v-model="form.target">
                      <el-radio :label="0">New Grad</el-radio>
                      <el-radio :label="1">Internship</el-radio>
                      <el-radio :label="2">合同工</el-radio>
                      <el-radio :label="3">跳槽</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="工作经验" prop="workExperience">
                    <el-select
                      v-model="form.workExperience"
                      placeholder="工作经验"
                    >
                      <el-option
                        label="fresh grad 无实习或全职"
                        :value="0"
                      ></el-option>
                      <el-option label="3个月实习或全职" :value="1"></el-option>
                      <el-option label="3个月-1年" :value="2"></el-option>
                      <el-option label="1-3年" :value="3"></el-option>
                      <el-option label="3-5年" :value="4"></el-option>
                      <el-option label="5-10年" :value="5"></el-option>
                      <el-option label="11-15年" :value="6"></el-option>
                      <el-option label="15年以上" :value="7"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="学历信息" prop="highestEducation">
                    <el-select
                      v-model="form.highestEducation"
                      placeholder="目前最高学历"
                    >
                      <el-option label="本科" :value="0"></el-option>
                      <el-option label="硕士" :value="1"></el-option>
                      <el-option label="博士" :value="2"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="所学专业" prop="specialty">
                    <el-select
                      v-model="form.specialty"
                      placeholder="请选择所学专业"
                    >
                      <el-option label="CS" :value="0"></el-option>
                      <el-option label="MIS" :value="1"></el-option>
                      <el-option label="EE" :value="2"></el-option>
                      <el-option label="ME" :value="3"></el-option>
                      <el-option label="BME" :value="4"></el-option>
                      <el-option label="ECON" :value="5"></el-option>
                      <el-option label="MFE" :value="6"></el-option>
                      <el-option label="ACCOUNTING" :value="7"></el-option>
                      <el-option label="其它" :value="8"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="毕业院校" prop="graduatedFrom">
                    <el-autocomplete
                      v-model="form.graduatedFrom"
                      :fetch-suggestions="querySchoolSearch"
                      :trigger-on-focus="false"
                      placeholder="国内大学请输入中文，国外大学请输入英文"
                      :popper-append-to-body="false"
                      @input="
                        (val) => {
                          searchSchoolLodash(val);
                        }
                      "
                      @select="selectSchool"
                    >
                      <template slot-scope="{ item }">
                        {{ item.name }}
                      </template>
                    </el-autocomplete>
                  </el-form-item>
                </el-form>
                <div class="collapse" v-if="!covered" @click="covered = true">
                  <i class="el-icon-d-arrow-left"></i>收起基础信息
                </div>
              </div>

              <div class="card-boder">
                <el-form
                  ref="interviewForm"
                  :model="interviewForm"
                  label-width="85px"
                  size="small"
                  class="ms-form"
                  :rules="interviewRules"
                >
                  <el-form-item
                    label="面试公司"
                    v-if="companyId == ''"
                    prop="searchCompanyKey"
                  >
                    <el-autocomplete
                      v-model="interviewForm.searchCompanyKey"
                      :fetch-suggestions="querySearch"
                      :trigger-on-focus="false"
                      placeholder="请输入公司英文名进行搜索"
                      :popper-append-to-body="false"
                      @input="searchCompanys"
                      @select="selectCompany"
                      @blur="blurEvents"
                      @keydown.native="interviewForm.companyId = ''"
                    >
                      <template slot-scope="{ item }">
                        {{ item.name }}
                      </template>
                    </el-autocomplete>
                    <!-- <span
                      style="font-size: 12px; color: #ff3b30"
                      v-if="interviewForm.companyId == ''"
                      >*如果提交面经的对应公司，不在上面搜索项中，则该面经需等待审核后发布</span
                    > -->
                  </el-form-item>
                  <el-form-item label="Offer情况" prop="offer">
                    <el-radio-group v-model="interviewForm.offer">
                      <el-radio :label="true">已拿到Offer</el-radio>
                      <el-radio :label="false">还未拿到Offer</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="机会来源" prop="source">
                    <el-select
                      v-model="interviewForm.source"
                      placeholder="请选择来源"
                    >
                      <el-option label="内推" :value="0"></el-option>
                      <el-option label="招聘会" :value="1"></el-option>
                      <el-option label="海投" :value="2"></el-option>
                      <el-option label="猎头" :value="3"></el-option>
                      <el-option
                        label="实习Return Offer"
                        :value="5"
                      ></el-option>
                      <el-option label="其它" :value="4"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="面经标题" prop="title">
                    <el-input
                      v-model="interviewForm.title"
                      placeholder="请输入面经标题"
                      maxlength="25"
                      show-word-limit
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="面经类型" required>
                    <div class="btn-group">
                      <el-checkbox-group
                        v-model="interviewForm.interviewExperienceType"
                      >
                        <el-checkbox label="HR">HR筛选</el-checkbox>
                        <el-checkbox label="PHONE_INTERVIEW"
                          >电话面试</el-checkbox
                        >
                        <el-checkbox label="ON_SITE_INTERVIEW"
                          >现场面试</el-checkbox
                        >
                        <el-checkbox label="ON_CAMPUS">校园招聘会</el-checkbox>
                        <el-checkbox label="WRITTEN_EXAMINATION"
                          >在线笔试</el-checkbox
                        >
                        <el-checkbox label="VIDEO_INTERVIEW"
                          >视频面试</el-checkbox
                        >
                      </el-checkbox-group>
                    </div>
                  </el-form-item>
                  <el-form-item
                    label="面试流程"
                    class="align-form-item"
                    prop="interviewAfter"
                  >
                    <el-input
                      type="textarea"
                      v-model="interviewForm.interviewAfter"
                      rows="4"
                      placeholder="面试的流程，非面经题"
                      maxlength="2000"
                      show-word-limit
                      :autosize="{ minRows: 4, maxRows: 20 }"
                    >
                    </el-input>
                  </el-form-item>

                  <el-form-item prop="interviewExperienceType">
                    <div
                      v-if="
                        interviewForm.interviewExperienceType &&
                        interviewForm.interviewExperienceType.indexOf('HR') !=
                          -1
                      "
                    >
                      <div class="ms-select">
                        # HR筛选 # 请填写面经题目，添加题目点击右侧+按钮
                        <span
                          ><i
                            class="el-icon-circle-plus-outline addIcon"
                            @click="addQuestion('hr')"
                          ></i
                        ></span>
                      </div>
                      <div
                        v-for="(item, index) in hrQuestionArray"
                        :key="index"
                      >
                        <el-input
                          type="textarea"
                          v-model="item.value"
                          style="width: 90%"
                          class="textarea-input"
                          rows="1"
                          :autosize="{ minRows: 1, maxRows: 20 }"
                          maxlength="1000"
                          placeholder="请填写面经题目"
                        ></el-input>
                        <span
                          ><i
                            class="el-icon-remove-outline deleteIcon"
                            @click="deleteQuestion('hr', index)"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="
                        interviewForm.interviewExperienceType &&
                        interviewForm.interviewExperienceType.indexOf(
                          'PHONE_INTERVIEW'
                        ) != -1
                      "
                    >
                      <div class="ms-select">
                        # 电话面试 # 请填写面经题目，添加题目点击右侧+按钮<span
                          ><i
                            class="el-icon-circle-plus-outline addIcon"
                            @click="addQuestion('phone')"
                          ></i
                        ></span>
                      </div>
                      <div
                        v-for="(item, index) in phoneInterviewQuestionArray"
                        :key="index"
                      >
                        <el-input
                          type="textarea"
                          v-model="item.value"
                          style="width: 90%"
                          class="textarea-input"
                          rows="1"
                          :autosize="{ minRows: 1, maxRows: 20 }"
                          maxlength="1000"
                          placeholder="请填写面经题目"
                        ></el-input>
                        <span
                          ><i
                            class="el-icon-remove-outline deleteIcon"
                            @click="deleteQuestion('phone', index)"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="
                        interviewForm.interviewExperienceType &&
                        interviewForm.interviewExperienceType.indexOf(
                          'ON_SITE_INTERVIEW'
                        ) != -1
                      "
                    >
                      <div class="ms-select">
                        # 现场面试 # 请填写面经题目，添加题目点击右侧+按钮<span
                          ><i
                            class="el-icon-circle-plus-outline addIcon"
                            @click="addQuestion('site')"
                          ></i
                        ></span>
                      </div>
                      <div
                        v-for="(item, index) in siteViewQuestionArray"
                        :key="index"
                      >
                        <el-input
                          type="textarea"
                          v-model="item.value"
                          style="width: 90%"
                          class="textarea-input"
                          rows="1"
                          :autosize="{ minRows: 1, maxRows: 20 }"
                          maxlength="1000"
                          placeholder="请填写面经题目"
                        ></el-input>
                        <span
                          ><i
                            class="el-icon-remove-outline deleteIcon"
                            @click="deleteQuestion('site', index)"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="
                        interviewForm.interviewExperienceType &&
                        interviewForm.interviewExperienceType.indexOf(
                          'ON_CAMPUS'
                        ) != -1
                      "
                    >
                      <div class="ms-select">
                        # 校园招聘会 #
                        请填写面经题目，添加题目点击右侧+按钮<span
                          ><i
                            class="el-icon-circle-plus-outline addIcon"
                            @click="addQuestion('campus')"
                          ></i
                        ></span>
                      </div>
                      <div
                        v-for="(item, index) in campusQuestionArray"
                        :key="index"
                      >
                        <el-input
                          type="textarea"
                          v-model="item.value"
                          style="width: 90%"
                          class="textarea-input"
                          rows="1"
                          :autosize="{ minRows: 1, maxRows: 20 }"
                          maxlength="1000"
                          placeholder="请填写面经题目"
                        ></el-input>
                        <span
                          ><i
                            class="el-icon-remove-outline deleteIcon"
                            @click="deleteQuestion('campus', index)"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="
                        interviewForm.interviewExperienceType &&
                        interviewForm.interviewExperienceType.indexOf(
                          'WRITTEN_EXAMINATION'
                        ) != -1
                      "
                    >
                      <div class="ms-select">
                        # 在线笔试 # 请填写面经题目，添加题目点击右侧+按钮<span
                          ><i
                            class="el-icon-circle-plus-outline addIcon"
                            @click="addQuestion('written')"
                          ></i
                        ></span>
                      </div>
                      <div
                        v-for="(item, index) in writtenQuestionArray"
                        :key="index"
                      >
                        <el-input
                          type="textarea"
                          v-model="item.value"
                          style="width: 90%"
                          class="textarea-input"
                          rows="1"
                          :autosize="{ minRows: 1, maxRows: 20 }"
                          maxlength="1000"
                          placeholder="请填写面经题目"
                        ></el-input>
                        <span
                          ><i
                            class="el-icon-remove-outline deleteIcon"
                            @click="deleteQuestion('written', index)"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="
                        interviewForm.interviewExperienceType &&
                        interviewForm.interviewExperienceType.indexOf(
                          'VIDEO_INTERVIEW'
                        ) != -1
                      "
                    >
                      <div class="ms-select">
                        # 视频面试 # 请填写面经题目，添加题目点击右侧+按钮<span
                          ><i
                            class="el-icon-circle-plus-outline addIcon"
                            @click="addQuestion('video')"
                          ></i
                        ></span>
                      </div>
                      <div
                        v-for="(item, index) in videoQuestionArray"
                        :key="index"
                      >
                        <el-input
                          type="textarea"
                          v-model="item.value"
                          style="width: 90%"
                          class="textarea-input"
                          rows="1"
                          :autosize="{ minRows: 1, maxRows: 20 }"
                          maxlength="1000"
                          placeholder="请填写面经题目"
                        ></el-input>
                        <span
                          ><i
                            class="el-icon-remove-outline deleteIcon"
                            @click="deleteQuestion('video', index)"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item label="图片说明">
                    <el-upload
                      ref="upload"
                      :before-upload="beforeFileUpload"
                      :on-success="handleFileSuccess"
                      :limit="9"
                      :on-remove="removeFile"
                      :action="uploadUrl"
                      :class="[
                        'upload-demo',
                        showUpload === false ? 'hide' : '',
                      ]"
                      multiple
                      accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
                      style="display: inline-block"
                      :data="{ isPublic: true }"
                      :headers="{ 'X-Access-Token': token }"
                      list-type="picture-card"
                      :on-exceed="onExceed"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </el-form-item>
                  <el-form-item
                    label="面试总结（选填）"
                    class="align-form-item"
                    prop="interviewSummary"
                  >
                    <el-input
                      type="textarea"
                      v-model="interviewForm.interviewSummary"
                      rows="4"
                      placeholder="面试的总体感受，整体体验"
                      maxlength="2000"
                      show-word-limit
                      :autosize="{ minRows: 4, maxRows: 20 }"
                    >
                    </el-input>
                  </el-form-item>
                  <div class="ms-checked">
                    <el-checkbox v-model="interviewForm.anonymity"
                      >匿名发布</el-checkbox
                    >
                  </div>
                </el-form>
              </div>
              <div class="form-save">
                <el-button
                  type="primary"
                  size="mini"
                  @click="submitInterview"
                  :disabled="buttonDisabled"
                  :loading="buttonLoading"
                >
                  保存发表
                </el-button>
              </div>
            </div>
          </div>
          <div class="ms-aside">
            <SideAdvertisement></SideAdvertisement>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  searchCompanys,
  publishExp,
  getExpBasicDetail,
  getSchools,
} from "@/service/company";
import SideAdvertisement from "@/components/sideAdvertisement";
import _ from "lodash";
export default {
  name: "interviewEdit",
  components: { SideAdvertisement },
  data() {
    var validateInterviewExp = (rule, value, callback) => {
      const details = [];
      this.hrQuestionArray.map((item) => {
        if (item.value != "") {
          details.push({
            interviewExperienceType: "0",
            interviewQuestions: item.value,
          });
        }
      });
      this.phoneInterviewQuestionArray.map((item) => {
        if (item.value != "") {
          details.push({
            interviewExperienceType: "1",
            interviewQuestions: item.value,
          });
        }
      });
      this.siteViewQuestionArray.map((item) => {
        if (item.value != "") {
          details.push({
            interviewExperienceType: "2",
            interviewQuestions: item.value,
          });
        }
      });
      this.campusQuestionArray.map((item) => {
        if (item.value != "") {
          details.push({
            interviewExperienceType: "3",
            interviewQuestions: item.value,
          });
        }
      });
      this.writtenQuestionArray.map((item) => {
        if (item.value != "") {
          details.push({
            interviewExperienceType: "4",
            interviewQuestions: item.value,
          });
        }
      });
      this.videoQuestionArray.map((item) => {
        if (item.value != "") {
          details.push({
            interviewExperienceType: "5",
            interviewQuestions: item.value,
          });
        }
      });

      if (details.length == 0) {
        callback(new Error("请填写面经题目"));
      } else {
        callback();
      }
    };
    return {
      covered: false,
      companyId: "",
      form: {
        post: "",
        target: "",
        workExperience: "",
        highestEducation: "",
        specialty: "",
        graduatedFrom: "",
      },
      interviewForm: {
        companyId: "",
        companyAuditAO: {
          companyName: "",
        },
        details: [],
        anonymity: false,
        interviewExperienceType: [],
      },

      companys: [],
      hrQuestionArray: [
        {
          value: "",
        },
      ],
      phoneInterviewQuestionArray: [
        {
          value: "",
        },
      ],
      siteViewQuestionArray: [
        {
          value: "",
        },
      ],
      campusQuestionArray: [
        {
          value: "",
        },
      ],
      writtenQuestionArray: [
        {
          value: "",
        },
      ],
      videoQuestionArray: [
        {
          value: "",
        },
      ],
      myDetailRules: {
        post: [
          { required: true, message: "请选择面试岗位", trigger: "change" },
        ],
        target: [
          { required: true, message: "请选择求职目标", trigger: "change" },
        ],
        workExperience: [
          { required: true, message: "请选择工作经验", trigger: "change" },
        ],
        highestEducation: [
          { required: true, message: "请选择最高学历", trigger: "change" },
        ],
        specialty: [
          { required: true, message: "请选择所学专业", trigger: "change" },
        ],
        graduatedFrom: [
          { required: true, message: "请填写毕业院校", trigger: "blur" },
        ],
      },
      interviewRules: {
        offer: [
          { required: true, message: "是否拿到offer？", trigger: "change" },
        ],
        interviewExperienceType: [
          {
            required: true,
            validator: validateInterviewExp,
            trigger: "blur",
          },
        ],
        source: [
          { required: true, message: "请选择机会来源", trigger: "change" },
        ],
        title: [{ required: true, message: "请填写标题", trigger: "blur" }],
        searchCompanyKey: [
          {
            required: () => {
              return this.companyId == "";
            },
            message: "请填写公司名称",
            trigger: "blur",
          },
        ],
        // interviewAfter: [
        //   { required: true, message: "请填写面试经过", trigger: "blur" },
        // ],
      },
      buttonLoading: false,
      buttonDisabled: false,
      timeout: null,
      interviewPictures: [],
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      showUpload: true,
      schools: [],
    };
  },
  methods: {
    beforeFileUpload(file) {
      if (this.interviewPictures.length >= 9) {
        return this.$message.error("最多只能上传9个文件!");
      }
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过10MB!");
      }
      return isLt10M;
    },
    handleFileSuccess(res) {
      this.interviewPictures.push(res.result.objectKey);
      if (this.interviewPictures.length >= 9) {
        this.showUpload = false;
      }
    },
    removeFile(file, fileList) {
      let removeIndex = this.interviewPictures.indexOf(
        file.response.result.objectKey
      );
      this.interviewPictures.splice(removeIndex, 1);
      this.showUpload = true;
    },
    querySearch(queryString, cb) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.companys);
      }, 1000);
    },
    searchCompanys() {
      if (this.interviewForm.searchCompanyKey) {
        searchCompanys(this.interviewForm.searchCompanyKey).then((res) => {
          if (res.success) {
            this.companys = res.result;
          }
        });
      }
    },
    selectCompany(option) {
      this.interviewForm.searchCompanyKey = option.name;
      this.interviewForm.companyId = option.id;
    },
    blurEvents() {
      if (this.companys.length == 0) {
        this.interviewForm.companyAuditAO.companyName =
          this.interviewForm.searchCompanyKey;
        this.interviewForm.companyId = "";
      } else {
        this.interviewForm.searchCompanyKey = this.companys[0].name;
        this.interviewForm.companyId = this.companys[0].id;
      }
    },
    addQuestion(type) {
      switch (type) {
        case "hr":
          this.hrQuestionArray.push({ value: "" });
          break;
        case "phone":
          this.phoneInterviewQuestionArray.push({ value: "" });
          break;
        case "site":
          this.siteViewQuestionArray.push({ value: "" });
          break;
        case "campus":
          this.campusQuestionArray.push({ value: "" });
          break;
        case "written":
          this.writtenQuestionArray.push({ value: "" });
          break;
        case "video":
          this.videoQuestionArray.push({ value: "" });
          break;
      }
    },
    deleteQuestion(type, index) {
      switch (type) {
        case "hr":
          this.hrQuestionArray.splice(index, 1);
          break;
        case "phone":
          this.phoneInterviewQuestionArray.splice(index, 1);
          break;
        case "site":
          this.siteViewQuestionArray.splice(index, 1);
          break;
        case "campus":
          this.campusQuestionArray.splice(index, 1);
          break;
        case "written":
          this.writtenQuestionArray.splice(index, 1);
          break;
        case "video":
          this.videoQuestionArray.splice(index, 1);
          break;
      }
    },
    submitInterview() {
      console.log(this.interviewForm);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$refs["interviewForm"].validate((innervalid) => {
            if (innervalid) {
              this.interviewForm.details = [];
              this.hrQuestionArray.map((item) => {
                if (item.value != "") {
                  this.interviewForm.details.push({
                    interviewExperienceType: "0",
                    interviewQuestions: item.value,
                  });
                }
              });
              this.phoneInterviewQuestionArray.map((item) => {
                if (item.value != "") {
                  this.interviewForm.details.push({
                    interviewExperienceType: "1",
                    interviewQuestions: item.value,
                  });
                }
              });
              this.siteViewQuestionArray.map((item) => {
                if (item.value != "") {
                  this.interviewForm.details.push({
                    interviewExperienceType: "2",
                    interviewQuestions: item.value,
                  });
                }
              });
              this.campusQuestionArray.map((item) => {
                if (item.value != "") {
                  this.interviewForm.details.push({
                    interviewExperienceType: "3",
                    interviewQuestions: item.value,
                  });
                }
              });
              this.writtenQuestionArray.map((item) => {
                if (item.value != "") {
                  this.interviewForm.details.push({
                    interviewExperienceType: "4",
                    interviewQuestions: item.value,
                  });
                }
              });
              this.videoQuestionArray.map((item) => {
                if (item.value != "") {
                  this.interviewForm.details.push({
                    interviewExperienceType: "5",
                    interviewQuestions: item.value,
                  });
                }
              });
              this.interviewForm.interviewExperienceBasicsAO = this.form;
              console.log(this.interviewForm);

              const newData = {
                ...this.interviewForm,
                pictures: this.interviewPictures,
              };
              delete newData.interviewExperienceType;
              delete newData.searchCompanyKey;
              publishExp(newData).then((res) => {
                console.log(res);
                this.buttonLoading = true;
                if (res.success) {
                  this.buttonLoading = false;
                  this.buttonDisabled = true;
                  this.$message.success("提交成功!");

                  this.$router.go(-1);
                }
              });
            } else {
              return false;
            }
          });
        } else {
          this.$message.error("请补充完整信息！");
          this.covered = false;
        }
      });
    },
    getExpBasicDetail() {
      getExpBasicDetail().then((res) => {
        if (res.success) {
          this.covered = !res.result.isOpen;
          this.form.id = res.result.id ? res.result.id : "";
          this.form.post = res.result.post ? res.result.post.value : "";
          this.form.target = res.result.target ? res.result.target.value : "";

          this.form.workExperience = res.result.workExperience
            ? res.result.workExperience.value
            : "";

          this.form.highestEducation = res.result.highestEducation
            ? res.result.highestEducation.value
            : "";

          this.form.specialty = res.result.specialty
            ? res.result.specialty.value
            : "";

          this.form.graduatedFrom = res.result.graduatedFrom
            ? res.result.graduatedFrom
            : "";
        }
      });
    },
    onExceed() {
      this.$message.error("最多上传9个文件！");
    },
    querySchoolSearch(queryString, cb) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.schools);
      }, 1000);
    },
    searchSchools(val) {
      getSchools(val).then((res) => {
        if (res.success) {
          this.schools = res.result;
        }
      });
    },
    selectSchool(option) {
      this.form.graduatedFrom = option.name;
    },
    searchSchoolLodash: _.debounce(function (val) {
      this.searchSchools(val);
    }, 1000),
  },
  mounted() {
    if (this.$route.query.companyId) {
      this.companyId = this.$route.query.companyId;
      this.interviewForm.companyId = this.$route.query.companyId;
    }
    this.getExpBasicDetail();
  },
};
</script>

<style lang="scss" scoped>
.orange {
  color: #fa6400 !important;
}

.blue {
  color: #2772db !important;
}

.green {
  color: #2ec1ac !important;
}

.red {
  color: #e02020 !important;
}

.ms-content {
  width: 890px;
  float: left;
}

.ms-aside {
  width: 300px;
  float: right;
}

.aside-course {
  font-size: 0;
}

.aside-zx {
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-top: 10px;
  padding: 22px 0;
  text-align: center;
}

.aside-zx-tt {
  font-size: 14px;
  color: #34495e;
  margin-bottom: 6px;
  text-align: center;
}

.breadcrumb {
  padding: 9px 0;
}

.breadcrumb .el-breadcrumb {
  font-size: 16px;
}

.ms-release {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  text-align: center;
  background-color: #fff;
  padding: 4px;
  margin-bottom: 10px;
}

.ms-release .el-button--text {
  font-size: 16px;
  color: #34495e;
}

.ms-number {
  padding: 5px 0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  text-align: center;
  background-color: #fff;
  display: flex;
  margin-bottom: 10px;
}

.ms-number .item {
  flex: 1;
  text-align: center;
  padding: 20px 0;
  border-right: 1px dashed rgba(0, 0, 0, 0.22);
}

.ms-number .item:last-child {
  border-right: 0;
}

.ms-number-val {
  font-size: 16px;
  line-height: 26px;
  color: #fa6400;
  margin-bottom: 2px;
}

.ms-number-label {
  font-size: 12px;
  line-height: 22px;
  color: #34495e;
}

.ms-report .el-button--text {
  color: #000000;
  opacity: 0.25;
}

.ms-report {
  width: 146px;
  text-align: center;
}

.my-card {
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 40px 45px;
}

.card-head {
  position: relative;
  padding: 13px 0;
  padding-left: 10px;
}

.pay-card-tit {
  font-size: 16px;
  color: #34495e;
}

.card-head::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  margin-top: -10px;
  width: 4px;
  height: 20px;
  background: #0a7aff;
  border-radius: 2px;
}

.card-boder {
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #eeeeee;
  margin-bottom: 10px;
  padding: 20px 95px;
  transition: 0.1s;
}

.ms-form .el-select,
.el-input {
  margin-bottom: 10px;
}

.ms-form .el-select {
  width: 253px;
}

.ms-form .el-select + .el-select {
  margin-left: 17px;
}

.btn-group {
  margin-bottom: 10px;
}

.btn-group .el-button {
  color: #d1d1d1;
}

.ms-select {
  font-size: 12px;
  color: #fa6400;
  border-radius: 2px;
  background-color: #ffe6d4;
  padding: 0 19px;
  line-height: 28px;
  margin-bottom: 10px;
}

.ms-checked {
  text-align: center;
}

.form-save {
  text-align: center;
  padding-top: 17px;
}

.form-save .el-button {
  width: 130px;
  background-color: #0075f6;
}

.zx-ewm {
  border-radius: 2px;
  border: 3px solid #0075f6;
  width: 106px;
  height: 106px;
  margin: 0 auto;
  font-size: 0;
}

.el-select /deep/.el-select__caret {
  font-size: 12px;
}

.el-breadcrumb /deep/ .el-breadcrumb__inner {
  color: #34495e !important;
}

.el-breadcrumb /deep/ .el-breadcrumb__inner.is-link {
  font-weight: 400;
}

::v-deep .el-collapse-item__header {
  font-size: 14px;
  font-weight: 600;

  color: #34495e;
}

::v-deep .el-autocomplete {
  display: block;
}
.addIcon {
  float: right;
  font-size: 16px;
  line-height: 28px;
  margin-right: 8px;
  cursor: pointer;
}
.deleteIcon {
  font-size: 16px;
  margin-left: 2%;
  line-height: 32px;
  cursor: pointer;
  vertical-align: top;
}
.hidden {
  position: relative;
  height: 170px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.5);
}
.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 170px;
  line-height: 250px;
  text-align: center;
  font-size: 20px;
  z-index: 999;
  cursor: pointer;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -o-linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -moz-linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -webkit-linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -ms-linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 1) 100%
  );
}
.cover i {
  transform: rotate(-90deg);
}
.collapse {
  text-align: center;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.collapse i {
  transform: rotate(90deg);
}
.textarea-input {
  margin-bottom: 6px;
}
::v-deep .hide .el-upload--picture-card {
  display: none !important;
}
::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  .el-icon-plus {
    vertical-align: top;
    line-height: 100px;
  }
}
</style>
